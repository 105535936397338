.homepage-reviews {
    padding-bottom: 80px;

    .title {
        text-align: center;
        margin: 30px 0 30px;

        h3 {
            font-size: 30px;
            font-weight: 400;
            text-align: center;
            color: #252629;
        }
    }


    .form-wrapper {
        display: flex;
        justify-content: center;
        height: 0px;

        &.excerpt {
            -webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 90%);
            mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 90%);
            transition: 1s;
            overflow: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        &.show {
            -webkit-mask-image: none;
            mask-image: none;
            transition: 1s ease-out;
        }

        form {
            width: 800px;

            textarea {
                width: 100%;
                padding: 10px;
            }

            .btn {
                border: 2px solid color('primary');
                padding: 5px 30px;

                &:hover {
                    border: 2px solid color('secondary');
                }
            }
        }
    }
}