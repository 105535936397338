/* FORM STYLES */
.dm-form-wrapper {
    margin-bottom: 3em;

    .form-group {
        label {
            font-size: 1rem;
        }
    }

    .form-control {
        font-size: 14px;
        padding: 5px 15px;
        height: 44px;

        &:focus {
            border-color: transparent;
        }
    }

    .text_widget {
        width: 100%;
    }

    textarea {
        height: 150px !important;
    }

    .checkbox {
        font-size: 14px;
        margin: 20px 0;

        label {
            margin: 0;
        }

        input[type="checkbox"] {
            width: 20px;
            height: 20px;
            float: left;
            margin-right: 10px;
        }

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    select {
        height: 44px !important;
    }

    .error {
        border-color: red !important;
    }

    &.col-2-form {
        margin-bottom: 0;

        >div {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .form-group {
                width: calc(50% - 7px);

                &.standalone-row {
                    width: 100%;

                    .form-control {
                        width: calc(50% - 7px);
                    }
                }

                &.full-row {
                    width: 100%;
                }
            }
        }
    }
}

.form-title {
    font-size: 1.6rem;
    font-family: font(main);
    margin-bottom: 3rem;

    .fa-sign-in {
        color: color('secondary');
    }

    .fa-key {
        color: color('primary');
    }

    +p {
        font-family: font(sub);
        font-size: 1.2rem;
        margin-bottom: 3rem;
    }
}

@media(max-width: $media_xs) {
    .dm-form-wrapper {
        &.col-2-form {
            >div {
                display: block;

                .form-group {
                    width: 100%;

                    &.standalone-row {
                        width: 100%;

                        .form-control {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}