.header-container {
    &.inverted {
        .contact-info {
            a,
            i {
                color: color('text', 'dark');
            }
        }

        .header-nav-main {
            .header-nav {
                .nav-link {
                    color: color('text', 'dark');
                }
            }
        }
    }

    .header-nav-main {
        .header-nav {
            .nav-item {
                .dropdown-menu {
                    color: color('text', 'dark');

                    &:hover {
                        color: color('text', 'dark');
                    }
                }
            }
        }
    }
}

html {
    body#homepage {
        .lang-wrap {
            .dropdown-menu {
                color: color('text', 'dark');

                a {
                    color: color('text', 'dark');

                    &:hover {
                        color: #FFF;
                    }
                }
            }
        }

        .header-container {
            @media (min-width: $media_lg) {
                padding: 20px 20px 0;

                &.scrolled {
                    padding: 0;
                }
            }

            .header-nav-main {
                .header-nav {
                    font-weight: 400;
                }

                .company-brand {
                    img {
                        height: 50px;
                    }
                }
            }
        }

        .header-slider-wrapper {
            .header-slider-item {
                height: 100vh;
                max-height: 740px;

                .container {
                    align-items: baseline;
                }
            }
        }

        .search-wrapper {
            display: block;

            .homepage-search {
                margin: 20px 0;
                position: relative;
                bottom: unset;
                left: unset;
                right: unset;
            }
        }

        .slider-section {
            padding-bottom: 40px;

            &.slider_v4 {
                .comments_slider_init {
                    .navi-prev {
                        top: -30px !important;
                        left: unset !important;
                        right: 40px;
                    }

                    .navi-next {
                        top: -30px !important;
                        right: 0 !important;
                    }
                }

                h2 {
                    font-size: 36px;
                    text-align: left;
                    margin-bottom: 20px;
                }

                .section-separator-home {
                    display: none;
                }

                .comment-text {
                    text-align: left;

                    .comment-quote-start,
                    .comment-quote-end {
                        display: none;
                    }

                    p {
                        margin: 0;
                        max-width: 100%;
                        font-weight: 400 !important;
                        line-height: 2;
                        padding-bottom: 0;
                        padding-left: 0;
                    }
                }

                .comment-author {
                    text-align: left;
                    font-weight: 400;
                    font-size: 15px;
                    color: color('text', 'dark');
                    margin-top: 20px;
                }
            }
        }

        .homepage-reviews {
            .form-wrapper {
                form {
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .accordion-btn {
                display: inline-block;
                color: color('text', 'dark');
                font-family: font(main);
                font-weight: 400;
                font-size: 15px;
                position: relative;

                @media (max-width: 992px) {
                    margin-bottom: 50px;
                }

                &::after {
                    content: '';
                    display: block;
                    box-shadow: -2px -2px 0 0px #222;
                    cursor: pointer;
                    transform: rotate(135deg);
                    width: 13px;
                    height: 13px;
                    position: absolute;
                    right: -15px;
                    top: 5px;
                    transition: all .3s;
                }

                &.rotate {
                    &::after {
                        transform: rotate(225deg);
                        top: 0;
                        right: -25px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

body:not(#homepage) {
    .header-nav-main {
        // border-bottom: 1px solid #e5e5e5;
        border-bottom: 0 !important;

        .header-nav {
            .nav-link {
                color: color('text', 'dark');
            }
        }
    }

    .btn-menu {
        span {
            background-color: #222;
        }
    }

    .lang-wrap,
    .contact-info {
        a,
        i {
            color: color('text', 'dark');
        }
    }

    .search-box {
        .search-box-triggers {
            &.group {
                color: color('text', 'dark');
            }
        }
    }

    .group-search {
        .SumoSelect {
            &.open {
                .optWrapper {
                    &.multiple {
                        .options {
                            li {
                                &.opt {
                                    //padding: 10px 15px;

                                    label {
                                        height: 16px;
                                        top: 1px;
                                    }
                                }
                            }
                        }

                        .options {
                            li {
                                &.opt {
                                    color: color('text', 'dark');

                                    &:hover {
                                        background-color: #f1f1f1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .range-col {
        &.active {
            p {
                background: transparent;
            }
        }

        p {
            color: color('text', 'dark');
        }

        .range-wrapper {
            li {
                input {
                    padding: 0 10px;
                }
            }
        }
    }

    #load-more-project,
    #load-more {
        margin-top: 20px;
    }
}

body {
    #detail-search {
        .select2-container--default {
            .select2-selection--multiple {
                .select2-search {
                    width: 99%;

                    input {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.detail-search {
    .search-box {
        .search-filters {
            .filter-item {
                label {
                    color: color('text', 'dark');
                }
            }
        }
    }

    footer {
        p {
            color: color('text', 'dark');
        }
    }
}

.header-slider-content {
    .header-slider-title {
        font-size: 36px;
        font-weight: 400;
    }
}

.main-footer {
    .footer-section {
        &.footer-main {
            padding-top: 40px;
            padding-bottom: 40px;

            .footer-contact {
                li {
                    color: color('text', 'dark');
                    font-weight: 400;

                    a {
                        color: color('text', 'dark');
                    }
                }
            }

            .footer-nav {
                ul {
                    li {
                        font-weight: 400;

                        a {
                            color: color('text', 'dark');

                            &:hover {
                                color: #808080;
                            }
                        }
                    }
                }
            }
        }

        h4 {
            &.block-title {
                text-transform: none;
                font-weight: 500;
                margin-bottom: 20px;

                &::after {
                    display: none;
                }
            }
        }
    }
}

html {
    body {
        color: color('text', 'dark');

        .search-box {
            input {
                width: 100%;

                &::placeholder {
                    color: color('text', 'dark');
                }
            }
        }

        .SumoSelect {
            > .CaptionCont {
                > span {
                    color: color('text', 'dark');

                    &.placeholder {
                        color: color('text', 'dark');
                    }
                }

                > label {
                    > i {
                        color: color('text', 'dark');
                    }
                }
            }
        }

        &#contact_page {
            .form-control {
                border-radius: 0;
            }
        }

        .section {
            p,
            .section-text {
                color: color('text', 'dark');
            }
        }

        .page-navigation {
            li {
                color: color('text', 'dark');
            }
        }

        .property-intro {
            ul {
                &.property-details {
                    font-size: 13px;
                    font-weight: 400;
                    color: color('text', 'dark');
                }
            }

            p {
                color: color('text', 'dark');

                &.price {
                    color: color('text', 'dark');
                    font-weight: 400;
                    font-size: 32px;
                    margin-top: 50px;

                    small {
                        top: -15px;
                    }
                }
            }
        }

        .property-information {
            ul {
                li {
                    color: color('text', 'dark');

                    span {
                        color: color('text', 'dark');
                        font-weight: 400;
                    }
                }
            }
        }

        .btn {
            &.btn-primary {
                background: #FFF;
                color: color('text', 'dark');
                border: 1px solid color('text', 'dark');

                &:active,
                &:focus,
                &:hover {
                    background: color('text', 'dark') !important;
                    color: #FFF !important;;
                }
            }
        }

        .property-section {
            border: 0;

            h2 {
                color: color('text', 'dark');
            }

            .property-description {
                p, ul {
                    color: color('text', 'dark');
                }
            }
        }

        .company-contact {
            li {
                color: color('text', 'dark');

                a,
                i {
                    color: color('text', 'dark');
                }
            }
        }

        .property-card {
            &.v5 {
                .property-text-section {
                    p {
                        font-size: 15px;
                        color: color('text', 'dark');
                    }
                }
            }

            &.v5,
            &.v6 {
                .card-body {
                    p {
                        color: color('text', 'dark');
                    }

                    ul {
                        li {
                            color: color('text', 'dark');

                            .price {
                                color: color('text', 'dark');
                            }
                        }
                    }
                }
            }
        }

        .homepage-section-default {
            .content-area {
                p {
                    color: color('text', 'dark');
                }
            }
        }

        .section.slider-section.slider_v4 .comment-text p {
            color: color('text', 'dark');
        }

        .agents {
            .agent-box {
                ul {
                    color: color('text', 'dark');
                }
            }
        }

        .social-media {
            .a2a_kit {
                a {
                    .a2a_svg {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }

        .contact-form {
            .checkbox {
                label {
                    color: color('text', 'dark');
                }
            }
        }

        .group-search {
            .search-box {
                .col {
                    &:first-of-type {
                        border-left: 1px solid #e5e5e5;
                    }
                }
            }
        }

        .search-selects {
            ::placeholder {
                color: color('text', 'dark');
            }
        }

        .page-team-info {
            li {
                color: color('text', 'dark');

                a {
                    color: color('text', 'dark');
                }
            }
        }

        .section {
            .section-text {
                a {
                    text-decoration: underline;
                }
            }
        }

        @media (min-width: 768px) {
            .property-card {
                .card-body {
                    h2 {
                        height: 45px;
                    }
                }
            }
        }

        .lang-wrap {
            .dropdown-item {
                &:hover {
                    color: #FFF !important;
                }
            }
        }
    }
}

@media (min-width: $media_md) {
    body#contact_page {
        .contact-section {
            .col-lg-8 {
                margin-top: -25px;
            }
        }
    }
}

@media (max-width: $media_lg) {
    html {
        body {
            .header-container .header-nav-main .company-brand img {
                height: 40px !important;
            }

            &#homepage {
                .header-slider-wrapper {
                    .header-slider-item {
                        height: 60vh;
                    }
                }

                .btn-menu {
                    padding-right: 25px;
                }

                .properties-container {
                    padding-bottom: 0;

                    #homepage-special-offer {
                        margin-bottom: 0;
                    }
                }

                .text-w-bg-image-section {
                    height: auto;
                    padding: 50px 0 0;
                }

                .homepage-reviews {
                    padding-bottom: 0;
                }

                .property-card.v5 {
                    .property-text-section {
                        width: 100%;
                    }
                }

                .properties-home-wrap .property-text-section {
                    top: 60px;
                }
            }

            .inverted {
                .btn-menu {
                    span {
                        background-color: #222;
                    }
                }
            }
        }
    }
}

.property-information ul li i {
    display: none;
}

.homepage-search .SumoSelect.sumo_type > .optWrapper > .options li label {
    color: color('text', 'dark') !important;
    top: 1px !important;
}

.property-intro ul,
.btn,
textarea::placeholder,
.form-control::placeholder,
::placeholder,
.form-control {
    color: color('text', 'dark') !important;
    opacity: 1;
}

.property-intro ul.property-actions,
html body .property-intro ul.property-details,
.property-intro p {
    font-size: 15px !important;
}

.dm-form-wrapper {
    .form-control {
        border-color: color('text', 'dark') !important;
    }
}

.homepage-reviews .form-wrapper form .btn {
    border: 1px solid #222222 !important;
}

html body.homepage .search-selects .input-group .input-group-append .btn {
    color: #FFF !important;
}

.inverted {
    .lang-wrap {
        a {
            color: color('text', 'dark');
        }
    }
}

html body .property-intro p.price {
    font-size: 32px !important;
}

.header-container .header-nav-main .header-nav .nav-item .dropdown-menu {
    background: #FFF;
}

#contact_page .btn,
#user-area-index .btn {
    color: #FFF !important;
}

#contact_page .btn:hover {
    color: #222 !important;
}