.page-team-info {
    margin: 20px 0 40px;

    li {
        line-height: 1;
        color: #d9d9d9;

        a {
            font-size: 14px;
        }
    }

    .page-team-name {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    .page-team-mail {
        margin-top: 5px;
        color: #d9d9d9;
    }
}

.our-team-item {
    margin-top: 20px;
}

.our-team-separator {
    border: none;
    height: 3px;
    background: color('section-secondary', '', '', $colors-section);
    color: color('section-secondary', '', '', $colors-section);
    width: 100px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 60px;
    float: left;
}

@media (max-width: 767px) {
    .page-team-info {
        text-align: center;
    }

    .page-team-text {
        text-align: center;
        width: auto;
    }
}